import React from 'react'
import { graphql } from 'gatsby'

import Layout from  '../components/common/layout'
import Seo from "../components/common/seo"

export const query = graphql`
  query (
    $url:String!
  ) {
    bigCommerceProducts(
      custom_url: {
        url:{
          eq:$url
        }
      }
    ) {
      id
      bigcommerce_id
      name
      description
      sku
      price
      calculated_price
      brand_id
      inventory_level
      inventory_tracking
    }
  }
`

const Product = (props) => {
  // When there is no data, condition
  // if (!props.data) return;
  const seoTitle = props.data.bigCommerceProducts.page_title??props.data.bigCommerceProducts.name
  return (    
    <Layout>
      <Seo
        title={seoTitle}
        description={props.data.bigCommerceProducts.meta_description}
        />
      <h1>{props.data.bigCommerceProducts.name}</h1>
      <p>sku: <strong>{props.data.bigCommerceProducts.sku}</strong></p>
      <p>price: <strong>{props.data.bigCommerceProducts.price}</strong></p>
      <p>calculated_price: <strong>{props.data.bigCommerceProducts.calculated_price}</strong></p>
      <p>brand_id: <strong>{props.data.bigCommerceProducts.brand_id}</strong></p>
      <p>inventory_level: <strong>{props.data.bigCommerceProducts.inventory_level}</strong></p>
      <p>inventory_tracking: <strong>{props.data.bigCommerceProducts.inventory_tracking}</strong></p>
      <article dangerouslySetInnerHTML={{ __html: props.data.bigCommerceProducts.description }}></article>
    </Layout>
  )
}

export default Product;